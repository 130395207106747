import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';

import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import { CommonSectionModule } from './common-section/common-section.module';
import { AuthInterceptor } from './utils/auth.interceptor';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from './services/snackbar.service';

/* 3rd party Angular components. */
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LoaderComponent } from './common-section/loader/loader.component';
import { LoaderInterceptor } from './utils/loader.interceptor';
import { GridModule } from 'smart-webcomponents-angular/grid';
import { FlexLayoutModule } from '@angular/flex-layout';
// Configure a custom TranslateLoader for AoT compilation.
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '/resources/i18n/', '.json');
}

window.Smart.License = "BEF3B03B-0387-45A0-B2BF-8594E6CF770C"; 

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    GridModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    CommonSectionModule,
    MatSnackBarModule,
    FlexLayoutModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
     // Translation application module.
     TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
  ],
  providers: [{
      provide:HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
   },
    { provide: 'WINDOW',  useValue: window },
    TranslateService,
    SnackbarService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
