import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    @Inject(DOCUMENT) public document: Document,
    public authenticationService: AuthenticationService,
    public httpClient: HttpClient,
    // private router: Router
  ){
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {

      // let user = JSON.parse(sessionStorage.getItem('userdata'));
      // let token = sessionStorage.getItem('token');
      // if(token && user.subcontractor_id){
      //   return true;
      // }
    //   this.router.navigateByUrl("/auth/login");
    // return false;

    // Return authenticated.
    return this.authenticationService.authenticate()
  }
}
