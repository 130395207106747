import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { catchError, map, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  /**
   * Name of the cookie received from the server on login.
   */
  COOKIE_NAME = 'jwt';

  localJwtToken = '';

  constructor(
    @Inject(DOCUMENT) public document: Document,
    public cookieService: CookieService,
    public httpClient: HttpClient,
    public router: Router,
  ) { }

  getToken() {
    return this.localJwtToken;
  }

  authenticate() {
    return this.httpClient.get(`${this.getAPIBaseURL()}/auth/authenticate`)
      .pipe(
        catchError(() => of({ authenticated: false })),
        map((res: any) => {
          if(!res?.authenticated){
            this.logout();
          }
          return !!res?.authenticated
        } 
      ))
  }

  refreshToken() {
    // `withCredentials` to add the refresh token cookie to the header.
    return this.httpClient.get(`${this.getAPIBaseURL()}/auth/refresh-token`, { withCredentials: true })
      .pipe(
        catchError(() => of({ authenticated: false })),
        map((res: any) => {
          const authenticated = !!res?.authenticated

          if (authenticated && res.token) {
            this.localJwtToken = res.token
          }

          return authenticated
        }),
      )
  }

  redirectToLogin() {
    this.localJwtToken = '';
    this.router.navigate(['/auth/login-selection'])
  }
  resetLogout() {
    this.httpClient.get(`${this.getAPIBaseURL()}/bids/clear-session`, { withCredentials: true }).subscribe(()=> {})
  }

  logout() {
   // this.resetLogout();
    // `withCredentials` to set the refresh token cookie to the header, in order to be removed.
    this.httpClient.get(`${this.getAPIBaseURL()}/auth/logout`, { withCredentials: true }).subscribe(()=> {
      sessionStorage.clear();
      this.cookieService.deleteAll();
      this.redirectToLogin()
      
    })
  }

  logoutAllDevices() {
    this.httpClient.get(`${this.getAPIBaseURL()}/auth/invalidate-refresh-tokens`).subscribe()
    this.redirectToLogin()
  }

  getAPIBaseURL() {
    const localhostApi = 'http://localhost:3200'
    const origin = this.document.location.origin
    return isDevMode() ? localhostApi : origin
  }
}
